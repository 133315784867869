import { NextPage } from "next"
import { useRef, useState, FormEvent, useEffect, FC } from "react"
import Head from "next/head"
import Image from "next/image"
import styled from "styled-components"
import { useCookies } from "react-cookie"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/nextjs"
import { auth } from "@utils/firebase"
import { addEvent } from "@utils/gtm"
import { useRouter } from "next/router"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
import { EnvelopeFill, PhoneFill } from "react-bootstrap-icons"
import { Button, ButtonProps, Col, Container, FloatingLabel, Form, Row, Spinner } from "react-bootstrap"
import { GoogleAuthProvider, RecaptchaVerifier, sendSignInLinkToEmail, signInWithEmailLink, signInWithPhoneNumber, signInWithPopup } from "firebase/auth"
import { DEFAULT_META_IMAGE_URL } from "@utils/constant"
import { useLogInMutation, useSignUpMutation } from "@generated/graphql"
import GoogleLoginButton from "@modules/Common/components/GoogleLoginButton"

const StyledButton: FC<ButtonProps> = styled(Button)`
  border: none;
  padding: 0.5rem;
  background: #ffd633;
  color: #000;
  :hover,:focus,:active{
    color: #000;
    background: rgba(255, 214, 51,0.7);
  }
`

const StyledSubmitButton: FC<ButtonProps> = styled(Button)`
  border: none;
  padding: 0.5rem;
  background: #ffd633;
  color: #000;
  :hover,:focus,:active{
    color: #000;
    background: rgba(255, 214, 51,0.7);
  }
`

const StyledFormControl = styled(Form.Control)`
  border: 1px solid #bdbdbd;
  padding: 0.5rem;
  border-radius: 2px;
  width: 100%;
  margin: 0.2rem;
  ::placeholder {
    color: #e2e2e2;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    color: #e2e2e2;
  }
  ::-ms-input-placeholder {
    color: #e2e2e2;
  }
`

const META_TITLE = "Join MySkillShaala Now | MySkillShaala"
const META_DESCRIPTION = "Join over 10,000+ people who have already signed up for free learning community. We have over 2,000 + activities on interesting skills like STEM, Technologies, Arts & Craft, and more."
const META_IMAGE = DEFAULT_META_IMAGE_URL

enum LOGIN_STATE {
  PHONE_NUMBER,
  OTP,
  EMAIL,
  USER_FORM,
  LOADING,
  EMAIL_SUBMIT
}

const provider = new GoogleAuthProvider()

const LogInPage: NextPage = () => {

  const router = useRouter()
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null)
  const phoneInputRef = useRef<any>(null)
  const codeRef = useRef<HTMLInputElement>(null)
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [userAge, setUserAge] = useState<number>()
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [isOTPLoggingIn, setIsOTPLoggingIn] = useState(false)
  const [signUpMutation] = useSignUpMutation()
  const [firebaseAuthToken, setFirebaseAuthToken] = useState("")
  const [loginState, setLoginState] = useState(LOGIN_STATE.PHONE_NUMBER)
  const [cookies, setCookie, removeCookie] = useCookies(["referralCode", "socialForSignIn", "emailForSignIn", "idToken", "expiresAt"])
  const [logInMutation] = useLogInMutation()

  useEffect(() => {
    if (router.query["code"]) {
      const now = new Date()
      now.setMinutes(now.getMinutes() + 5)
      setCookie("referralCode", router.query["code"], {
        path: "/",
        expires: now,
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        sameSite: "lax"
      })
    }
  }, [router, setCookie])

  useEffect(() => {
    if (cookies.idToken !== undefined) {
      router.push({ pathname: "/dashboard" })
    } else if (router.query["apiKey"] && cookies.emailForSignIn) {
      setLoginState(LOGIN_STATE.LOADING)
      signInWithEmailLink(auth, cookies.emailForSignIn, window.location.href)
        .then(result => result.user.getIdToken(true))
        .then(token => {
          return handleLogin(token)
        }).catch(error => {
          removeCookie("emailForSignIn")
          removeCookie("socialForSignIn")
          toast(error.message, {
            type: "error"
          })
        })
    }
  }, [cookies, router])

  async function handlePhoneNumberSubmit(e: FormEvent) {
    e.preventDefault()
    if (phoneNumber.length === 0) {
      toast("Enter valid phone number!", {
        type: "error",
      })
      return
    } else if (phoneNumber.startsWith("91") && phoneNumber.length !== 12) {
      toast("Enter valid phone number!", {
        type: "error"
      })
      setInterval(() => { phoneInputRef.current?.focus() }, 200)
      return
    }

    setIsLoggingIn(true)
    const localWindow = (window as any)
    const recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      "size": "invisible"
    })
    localWindow.recaptchaVerifier = recaptchaVerifier
    await recaptchaVerifier.render()
    signInWithPhoneNumber(auth, `+${phoneNumber}`, recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setIsLoggingIn(false)
        setLoginState(LOGIN_STATE.OTP)
        localWindow.confirmationResult = confirmationResult
      })
      .catch((error: Error) => {
        toast(error.message, {
          type: "error"
        })
        setIsLoggingIn(false)
        setLoginState(LOGIN_STATE.PHONE_NUMBER)
        Sentry.captureException(error)
        if ((window as any).recaptchaVerifier && recaptchaWrapperRef.current) {
          (window as any).recaptchaVerifier.clear()
          recaptchaWrapperRef.current.innerHTML = "<div id=\"recaptcha-container\"></div>"
        }
      })
  }

  async function onOTPSubmit(e: FormEvent) {
    e.preventDefault()
    setIsOTPLoggingIn(true)
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      const localWindow = (window as any)
      try {
        await localWindow.confirmationResult.confirm(codeRef.current!!.value)
        const token = await auth.currentUser?.getIdToken(true)
        if (token) {
          handleLogin(token)
        }
      } catch (error: any) {
        toast(error.message, {
          type: "error"
        })
        // Sentry.captureException(error)
        setLoginState(LOGIN_STATE.PHONE_NUMBER)
        setIsOTPLoggingIn(false)
        if ((window as any).recaptchaVerifier && recaptchaWrapperRef.current) {
          (window as any).recaptchaVerifier.clear()
          recaptchaWrapperRef.current.innerHTML = "<div id=\"recaptcha-container\"></div>"
        }
      }
    }
  }

  async function handleLogin(token: string) {
    setFirebaseAuthToken(token)
    try {
      const response = await logInMutation({
        variables: {
          data: {
            idToken: token
          }
        }
      })
      if (response.data?.logIn.success) {
        setCookie("idToken", response.data?.logIn.authToken, {
          path: "/",
          expires: new Date(`${response.data?.logIn.expiresAt}`),
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
          sameSite: "lax"
        })
        setCookie("expiresAt", response.data?.logIn.expiresAt, {
          path: "/",
          expires: new Date(`${response.data?.logIn.expiresAt}`),
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
          sameSite: "strict"
        })
        removeCookie("emailForSignIn")
        removeCookie("socialForSignIn")
        if (router.query.redirect) {
          window.location.href = router.query.redirect as string
        } else {
          window.location.href = "/dashboard"
        }
      } else {
        setLoginState(LOGIN_STATE.USER_FORM)
      }
    } catch (error: any) {
      removeCookie("emailForSignIn")
      removeCookie("socialForSignIn")
      toast(error.message, {
        type: "error"
      })
      // Sentry.captureException(error)
      setLoginState(LOGIN_STATE.PHONE_NUMBER)
      setIsOTPLoggingIn(false)
      if ((window as any).recaptchaVerifier && recaptchaWrapperRef.current) {
        (window as any).recaptchaVerifier.clear()
        recaptchaWrapperRef.current.innerHTML = "<div id=\"recaptcha-container\"></div>"
      }
    } finally {
      removeCookie("emailForSignIn")
      removeCookie("socialForSignIn")
    }
  }

  async function handleEmailFormSubmit(e: FormEvent) {
    e.preventDefault()
    const form = new FormData(e.target as any)
    if (form.has("email")) {
      const email = form.get("email") as string
      setIsLoggingIn(true)
      sendSignInLinkToEmail(auth, email, { url: window.location.href, handleCodeInApp: true })
        .then((res) => {
          const now = new Date()
          now.setMinutes(now.getMinutes() + 1)
          setCookie("emailForSignIn", email, {
            path: "/",
            expires: now,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            sameSite: "lax"
          })
          setLoginState(LOGIN_STATE.EMAIL_SUBMIT)
        })
        .catch((error) => {
          Sentry.captureException(error)
        }).finally(() => {
          setIsLoggingIn(false)
        })
    }
  }

  function handleUserFormSubmit(e: FormEvent) {
    e.preventDefault()
    const form = new FormData(e.target as any)
    let standardId: number = 1
    const studentAge = Number.parseInt(form.get("age") as string)
    if (studentAge < 6) {
      standardId = 12
    } else if (studentAge === 6) {
      standardId = 1
    } else if (studentAge === 7) {
      standardId = 2
    } else if (studentAge === 8) {
      standardId = 3
    } else if (studentAge === 9) {
      standardId = 4
    } else if (studentAge === 10) {
      standardId = 5
    } else if (studentAge === 11) {
      standardId = 6
    } else if (studentAge === 12) {
      standardId = 7
    } else if (studentAge === 13) {
      standardId = 8
    } else if (studentAge === 14) {
      standardId = 9
    } else if (studentAge === 15) {
      standardId = 10
    } else {
      standardId = 11
    }
    signUpMutation({
      variables: {
        data: {
          idToken: firebaseAuthToken,
          firstName: form.get("firstName") as string,
          lastName: form.get("lastName") as string,
          signUpCode: form.get("referralCode") as string,
          standard: { id: standardId },
          country: "IN",
        },
      },
    }).then((res) => {
      addEvent({
        "event": "sign_up",
        "user_id": res.data?.signUp?.id
      })
      if (res.data) {
        setCookie("idToken", res.data.signUp.authToken, {
          path: "/",
          expires: new Date(`${res.data.signUp.expiresAt}`),
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
          sameSite: "lax"
        })
        setCookie("expiresAt", res.data.signUp.expiresAt, {
          path: "/",
          expires: new Date(`${res.data.signUp.expiresAt}`),
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
          sameSite: "strict"
        })
        if (router.query.redirect) {
          window.location.href = router.query.redirect as string
        } else {
          window.location.href = "/welcome"
        }
      } else {
        toast("Oops, something went wrong!", {
          type: "error"
        })
      }
    }).catch((error: Error) => {
      toast(error.message, {
        type: "error"
      })
      Sentry.captureException(error)
    })
  }

  return (
    <>
      <Head>
        <title>{META_TITLE}</title>
        <meta name="title" content={META_TITLE} />
        <meta name="description" content={META_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}/register`} />
        <meta property="og:site_name" content={process.env.NEXT_PUBLIC_SITE_URL} />
        <meta property="og:title" content={META_TITLE} />
        <meta property="og:description" content={META_DESCRIPTION} />
        <meta property="og:image" content={META_IMAGE} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}/register`} />
        <meta property="twitter:title" content={META_TITLE} />
        <meta property="twitter:description" content={META_DESCRIPTION} />
        <meta property="twitter:image" content={META_IMAGE} />
      </Head>
      <Container fluid
        className="vh-100 login-bg">
        <Row className="p-2 p-md-0 h-100 d-flex align-items-center justify-content-center text-center">
          <Col md={4} sm={8}>
            <div className="">
              <Image alt="logo" src="/assets/img/skillshaala-logo.webp" height="167" width="200" />
              <h1 className="sign-in-title mt-3 mb-3">Log In</h1>
              {loginState === LOGIN_STATE.PHONE_NUMBER && <>
                <Form autoComplete="off" onSubmit={handlePhoneNumberSubmit}>
                  <PhoneInput
                    enableSearch
                    autoFormat={false}
                    country={"in"}
                    inputProps={{ ref: phoneInputRef }}
                    placeholder="Enter phone number"
                    containerClass="phone-input-container"
                    inputClass="phone-input"
                    countryCodeEditable={false}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    dropdownStyle={{ maxHeight: "300px", textAlign: "start" }}
                    onEnterKeyPress={handlePhoneNumberSubmit}
                  />
                  <StyledSubmitButton className="mt-3 w-100 d-block fw-semibold" disabled={isLoggingIn} type="submit">
                    {isLoggingIn && <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="white"
                      className="me-2"
                    />}Send OTP</StyledSubmitButton>
                </Form>
                <div className="separator">OR</div>
                <Row>
                  <Col xs={6}>
                    <StyledSubmitButton type="button" className="w-100 fw-semibold" onClick={() => setLoginState(LOGIN_STATE.EMAIL)}><EnvelopeFill className="me-2" />Email</StyledSubmitButton>
                  </Col>
                  <Col xs={6}>
                    <GoogleLoginButton onClick={() => {
                      const now = new Date()
                      now.setMinutes(now.getMinutes() + 1)
                      setCookie("socialForSignIn", "google", {
                        path: "/",
                        expires: now,
                        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        sameSite: "lax"
                      })
                      signInWithPopup(auth, provider)
                        .then(result => result.user.getIdToken(true))
                        .then(token => handleLogin(token))
                        .catch(error => {
                          toast(error.message, {
                            type: "error"
                          })
                        }).finally(() => {
                          removeCookie("socialForSignIn")
                        })
                    }} />
                  </Col>
                </Row>
              </>}
              {loginState === LOGIN_STATE.OTP && <>
                <Form autoComplete="off" onSubmit={onOTPSubmit}>
                  <FloatingLabel label="OTP">
                    <Form.Control ref={codeRef} type="tel" name="code" className="otp" placeholder="Enter OTP" minLength={6} maxLength={6} required />
                  </FloatingLabel>
                  <Row className="mt-3">
                    <Col>
                      <div className="d-grid">
                        <StyledButton onClick={((e: any) => {
                          setLoginState(LOGIN_STATE.PHONE_NUMBER)
                          if ((window as any).recaptchaVerifier && recaptchaWrapperRef.current) {
                            (window as any).recaptchaVerifier.clear()
                            recaptchaWrapperRef.current.innerHTML = "<div id=\"recaptcha-container\"></div>"
                          }
                        })}>Back</StyledButton>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-grid">
                        <Button variant="primary" disabled={isOTPLoggingIn} type="submit">
                          {isOTPLoggingIn && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            color="white"
                            className="me-2"
                          />}Submit OTP</Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </>}
              {loginState === LOGIN_STATE.EMAIL && <>
                <Form onSubmit={handleEmailFormSubmit}>
                  <FloatingLabel label="Email">
                    <Form.Control type="email" name="email" placeholder="Enter Email" autoFocus required />
                  </FloatingLabel>
                  <div className="d-grid mt-3">
                    <StyledSubmitButton type="submit">
                      {isLoggingIn && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        color="white"
                        className="me-2"
                      />}Send Email</StyledSubmitButton>
                  </div>
                </Form>
                <div className="separator">OR</div>
                <Row>
                  <Col xs={6}>
                    <div className="d-block">
                      <StyledSubmitButton type="button" className="w-100" onClick={() => setLoginState(LOGIN_STATE.PHONE_NUMBER)}><PhoneFill className="me-2" />Phone</StyledSubmitButton>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <GoogleLoginButton onClick={() => {
                      const now = new Date()
                      now.setMinutes(now.getMinutes() + 1)
                      setCookie("socialForSignIn", "google", {
                        path: "/",
                        expires: now,
                        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        sameSite: "lax"
                      })
                      signInWithPopup(auth, provider)
                        .then(result => result.user.getIdToken(true))
                        .then(token => handleLogin(token))
                        .catch(error => {
                          toast(error.message, {
                            type: "error"
                          })
                        }).finally(() => {
                          removeCookie("socialForSignIn")
                        })
                    }} />
                  </Col>
                </Row>
              </>}
              {loginState === LOGIN_STATE.EMAIL_SUBMIT && <>
                Your magic link awaits you in your inbox! Click on the link in email to unlock fun.
              </>}
              {loginState === LOGIN_STATE.LOADING && <>
                <Spinner
                  as="span"
                  animation="border"
                  color="white"
                  className="me-2"
                />
              </>}
              <br />
              {loginState === LOGIN_STATE.USER_FORM && <>
                <Form autoComplete="off" onSubmit={handleUserFormSubmit}>
                  <FloatingLabel label="First Name">
                    <StyledFormControl type="text" name="firstName" placeholder="Enter First Name" required />
                  </FloatingLabel>
                  <FloatingLabel label="Last Name">
                    <StyledFormControl type="text" name="lastName" placeholder="Enter Last Name" required />
                  </FloatingLabel>
                  <FloatingLabel label="Age">
                    <StyledFormControl type="number" name="age" value={userAge} placeholder="Age" min={4} max={99} maxLength={2} required onBlur={(e: any) => {
                      if (e.target.value) {
                        const localAge = parseInt(e.target.value)
                        if (localAge < 4) setUserAge(4)
                      } else setUserAge(4)
                    }} />
                  </FloatingLabel>
                  <FloatingLabel label="Referral Code">
                    <StyledFormControl type="text" name="referralCode" defaultValue={cookies["referralCode"]} placeholder="Enter Referral Code" />
                  </FloatingLabel>
                  <div className="d-block">
                    <StyledSubmitButton className="mt-2 w-100" size="lg" type="submit">Submit</StyledSubmitButton>
                  </div>
                </Form>
              </>}
              <br />
              <div className="text-center mt-2 mx-auto lh-1">
                <p>By signing in to MySkillShaala, you agree to our <a href="https://myskillshaala.com/tnc/" target="_blank">Terms</a> and <a href="https://myskillshaala.com/privacy/" target="_blank">Privacy Policy</a>.</p>
                <p>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
              </div>
              <div ref={recaptchaWrapperRef}>
                <div id="recaptcha-container"></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default LogInPage
